@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Gibson';
  src: url(/static/media/Gibson-SemiBold.38e60fc1.otf) format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Gibson';
  src: url(/static/media/Gibson-Regular.b3634764.otf) format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Digital-7Mono';
  src: url(/static/media/digital-7-mono.ae951a18.ttf) format('opentype');
  font-weight: normal;
}

